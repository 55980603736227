import React, { Fragment, useState, useEffect } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const MappingItem = (props) => {
  const [columnValue, setColumnValue] = useState("Ignore")
  const dbColumns = [
    "Procedure",
    "Date",
    "Location",
    "MRN",
    "DOB",
    "Age",
    "Gender",
    "Diagnosis",
    "Complication",
    "Outcome",
    "Duration",
  ]

  const compareStringsWithDifferentSpell = (str1, str2) => {
    // Remove punctuation, whitespace, and convert to lowercase for comparison
    const cleanStr1 = str1
      .replace(/[^\w\s]/gi, "")
      .trim()
      .toLowerCase()
    const cleanStr2 = str2
      .replace(/[^\w\s]/gi, "")
      .trim()
      .toLowerCase()

    // Define an object with keys as the correct spellings and values as arrays of alternative spellings
    const spellingDict = {
      procedure: ["procedure", "procedurename", "procedures", "proceduretype"],
      dob: [
        "dob",
        "date of birth",
        "dateofbirth",
        "date of birthday",
        "dateofbirthday",
      ],
      sex: ["gender", "sex"],
      mrn: ["mrn", "no", "number"],
      date: ["date", "proceduredate"],
      location: ["location", "thelocation"],
      age: ["age", "old", "patientage"],
      diagnosis: ["diagnosis", "diagnosisanddes"],
      complication: ["casecomplications"],
      outcome: ["caseoutcome"],
      duration: ["caseduration_mins"],
      // Add more key-value pairs as needed for other words with alternative spellings
    }

    // Loop through each key in the dictionary
    for (let key in spellingDict) {
      // If the first string matches a key in the dictionary, check if the second string matches any alternative spellings
      if (cleanStr1 === key) {
        const alternativeSpellings = spellingDict[key]
        for (let i = 0; i < alternativeSpellings.length; i++) {
          if (cleanStr2 === alternativeSpellings[i]) {
            return true
          }
        }
      }
      // If the second string matches a key in the dictionary, check if the first string matches any alternative spellings
      if (cleanStr2 === key) {
        const alternativeSpellings = spellingDict[key]
        for (let i = 0; i < alternativeSpellings.length; i++) {
          if (cleanStr1 === alternativeSpellings[i]) {
            return true
          }
        }
      }
    }

    // If none of the keys and alternative spellings match, return false
    return false
  }

  useEffect(() => {
    dbColumns.forEach((name) => {
      if (compareStringsWithDifferentSpell(props.item, name)) {
        setColumnValue(name)
        props.onChange(props.item, name)
      }
    })
    // eslint-disable-next-line
  }, [props.item])

  return (
    <div className="mt-2 grid grid-cols-2">
      <p className="text-sm text-gray-500 flex justify-end items-center pr-3">
        {props.item}
      </p>
      <Menu as="div" className="relative inline-block text-left pl-2">
        <div>
          <Menu.Button className="inline-flex justify-start gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {columnValue}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {dbColumns.map((name, index) => (
              <div className="py-1" key={index}>
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm cursor-pointer"
                      )}
                      onClick={(e) => {
                        setColumnValue(e.target.innerText)
                        props.onChange(props.item, e.target.innerText)
                      }}
                    >
                      {name}
                    </span>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
