import HeaderLogo from "./header_logo.svg"
import DarkLogo from "./dark_logo.svg"
import WhiteLogo from "./white_logo.svg"

const logos = {
  HeaderLogo,
  DarkLogo,
  WhiteLogo,
}

export default logos
