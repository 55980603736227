import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { Link } from "react-router-dom"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import { useTranslation } from "react-i18next"
import { logos } from "../../assets"

const languages = [
  {
    name: "English",
    key: "en",
  },
  {
    name: "French",
    key: "fr",
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Header = () => {
  const { t, i18n } = useTranslation("translation")
  return (
    <Popover className="relative bg-gray-800">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1 lg:grow-[8]">
            <Link to="/">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={logos.WhiteLogo}
                alt="Logo"
              />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-white" : "text-white",
                      "group inline-flex items-center rounded-md bg-gray-800 text-base font-medium hover:text-white focus:outline-none"
                    )}
                  >
                    <span>{t("navbar.language")}</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-white" : "text-white",
                        "ml-2 h-5 w-5 group-hover:text-white"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      className={`absolute z-10 -ml-4 mt-3 max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2`}
                    >
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-1 bg-white sm:gap-4 sm:p-4">
                          {languages.map((item) => (
                            <Link
                              key={item.name}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                            >
                              <p
                                className="text-base font-medium text-gray-900"
                                onClick={() => {
                                  i18n.changeLanguage(item.key)
                                }}
                              >
                                {t(`navbar.${item.name}`)}
                              </p>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            {/* <Link to="/pricing" className="text-base font-medium text-white">
              {t("navbar.pricing")}
            </Link>
            <Link to="/docs" className="text-base font-medium text-white">
              {t("navbar.docs")}
            </Link> */}
          </Popover.Group>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <Link
              to="/signin"
              className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700"
            >
              {t("navbar.login")}
            </Link>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={logos.WhiteLogo}
                    alt="Logo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  <span className="ml-3 text-base font-bold text-white">
                    {t("navbar.select-language")}
                  </span>
                  {languages.map((item) => (
                    <Link
                      key={item.name}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <span
                        className="ml-3 text-base text-white"
                        onClick={() => {
                          i18n.changeLanguage(item.key)
                        }}
                      >
                        {t(`navbar.${item.name}`)}
                      </span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {/* <Link
                  to="/pricing"
                  className="text-base font-medium text-white hover:text-gray-700"
                >
                  {t("navbar.pricing")}
                </Link>

                <Link
                  to="/docs"
                  className="text-base font-medium text-white hover:text-gray-700"
                >
                  {t("navbar.docs")}
                </Link> */}
              </div>
              <div>
                <Link
                  to="/signin"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700"
                >
                  {t("navbar.login")}
                </Link>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
