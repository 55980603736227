import { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  BookOpenIcon,
  XMarkIcon,
  HomeIcon,
  Cog6ToothIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import {
  MagnifyingGlassIcon,
  UserIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logos } from "../../assets";
import axiosInstance from "../../utils/axios";
import { useAuth } from "../../context/useAuth";
import { useContent } from "../../context/useContent";

const userNavigation = [{ name: "yourProfile", href: "/profile" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Layout = ({ children }) => {
  const auth = useAuth();
  const savedData = useContent();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation("translation");
  const id = localStorage.getItem("id");
  const [navigation, setNavigation] = useState([]);
  const [reviewCount, setReviewCount] = useState("");

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/userId/${id}`,
    })
      .then((res) => {
        auth.saveUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    savedData.saveSearch(searchValue);
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/reviews/counts`,
      params: {
        userid: id,
      },
    })
      .then((res) => {
        const count = res.data;
        console.log("Received review count: ", count); // Log the received count
        setReviewCount(count > 99 ? "99+" : count.toString());
      })
      .catch((err) => {
        console.error("Error fetching review counts:", err);
      });
  }, [id]);

  const adminSidebar = [
    { name: "specialty", href: "/admin/specialties" },
    { name: "procedure", href: "/admin/procedures" },
    { name: "user", href: "/admin/users" },
    { name: "institution", href: "/admin/institutions" },
    { name: "stats", href: "/admin/stats" },
  ];

  useEffect(() => {
    if (auth.account.role === "admin") {
      setNavigation([
        {
          name: "dashboard",
          href: "/dashboard",
          icon: HomeIcon,
          current: true,
        },
        {
          name: "mdlogbook",
          href: "/mdlogbook",
          icon: BookOpenIcon,
          current: false,
        },
        {
          name: "setting",
          href: "/settings",
          icon: Cog6ToothIcon,
          current: false,
        },
        {
          name: "admin",
          icon: UserCircleIcon,
          current: false,
          children: adminSidebar,
        },
      ]);
    } else {
      setNavigation([
        {
          name: "dashboard",
          href: "/dashboard",
          icon: HomeIcon,
          current: true,
        },
        {
          name: "mdlogbook",
          href: "/mdlogbook",
          icon: BookOpenIcon,
          current: false,
        },
        {
          name: "setting",
          href: "/settings",
          icon: Cog6ToothIcon,
          current: false,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [auth]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4 justify-center">
                    <img
                      className="h-8 w-auto cursor-pointer"
                      src={logos.WhiteLogo}
                      alt="Logo"
                      onClick={(e) => {
                        e.preventDefault();
                        if (localStorage.getItem("accessToken")) {
                          navigate("/dashboard");
                        } else {
                          navigate("/");
                        }
                      }}
                    />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="flex flex-1 flex-col px-2 py-4">
                      <ul className="space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            {!item.children ? (
                              <NavLink
                                to={item.href}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive
                                      ? "bg-gray-900 text-white"
                                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                    "group flex items-center px-2 py-2 text-sm font-medium gap-x-3 rounded-md"
                                  )
                                }
                                onClick={() => setSearchValue("")}
                              >
                                <item.icon
                                  className="h-6 w-6 shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                {t(`sidebar.${item.name}`)}
                              </NavLink>
                            ) : (
                              <Disclosure
                                as="div"
                                defaultOpen={item.children.some((child) =>
                                  location.pathname.startsWith(child.href)
                                )}
                              >
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={classNames(
                                        item.current
                                          ? "bg-gray-900"
                                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                        "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-300"
                                      )}
                                    >
                                      <item.icon
                                        className="h-6 w-6 shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      {t(`sidebar.${item.name}`)}
                                      <ChevronRightIcon
                                        className={classNames(
                                          open
                                            ? "rotate-90 text-white"
                                            : "text-gray-300",
                                          "ml-auto h-5 w-5 shrink-0"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Disclosure.Button>
                                    {
                                      <Disclosure.Panel
                                        as="ul"
                                        className="mt-1 px-2"
                                      >
                                        {item.children.map((subItem) => (
                                          <li key={subItem.name}>
                                            {/* 44px */}
                                            <NavLink
                                              to={subItem.href}
                                              className={({ isActive }) =>
                                                classNames(
                                                  isActive
                                                    ? "bg-gray-900"
                                                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                  "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-300"
                                                )
                                              }
                                              onClick={() => setSearchValue("")}
                                            >
                                              {t(`sidebar.${subItem.name}`)}
                                            </NavLink>
                                          </li>
                                        ))}
                                      </Disclosure.Panel>
                                    }
                                  </>
                                )}
                              </Disclosure>
                            )}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
            <div className="flex h-16 flex-shrink-0 items-center bg-gray-900 px-4 justify-center">
              <img
                className="h-8 w-auto cursor-pointer"
                src={logos.WhiteLogo}
                alt="Logo"
                onClick={(e) => {
                  e.preventDefault();
                  if (localStorage.getItem("accessToken")) {
                    navigate("/dashboard");
                  } else {
                    navigate("/");
                  }
                }}
              />
            </div>
            <div className="flex flex-1 flex-col overflow-y-auto">
              <nav className="flex flex-1 flex-col px-2 py-4">
                <ul className="space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      {!item.children ? (
                        <NavLink
                          to={item.href}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "group flex items-center px-2 py-2 text-sm font-medium gap-x-3 rounded-md"
                            )
                          }
                          onClick={() => setSearchValue("")}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {t(`sidebar.${item.name}`)}
                        </NavLink>
                      ) : (
                        <Disclosure
                          as="div"
                          defaultOpen={item.children.some((child) =>
                            location.pathname.startsWith(child.href)
                          )}
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current
                                    ? "bg-gray-900"
                                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                  "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-300"
                                )}
                              >
                                <item.icon
                                  className="h-6 w-6 shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                {t(`sidebar.${item.name}`)}
                                <ChevronRightIcon
                                  className={classNames(
                                    open
                                      ? "rotate-90 text-white"
                                      : "text-gray-300",
                                    "ml-auto h-5 w-5 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              {
                                <Disclosure.Panel as="ul" className="mt-1 px-2">
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      {/* 44px */}
                                      <NavLink
                                        to={subItem.href}
                                        className={({ isActive }) =>
                                          classNames(
                                            isActive
                                              ? "bg-gray-900"
                                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                            "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-300"
                                          )
                                        }
                                        onClick={() => setSearchValue("")}
                                      >
                                        {t(`sidebar.${subItem.name}`)}
                                      </NavLink>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              }
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:pl-64">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1">
                <form className="flex w-full md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <MagnifyingGlassIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search-field"
                      className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 relative"
                  onClick={() => {
                    setSearchValue("");
                    navigate("/notifications");
                  }}
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                  {reviewCount && (
                    <div className="absolute top-0 right-0 bg-red-500 rounded-full h-4 w-4 flex items-center justify-center">
                      <span className="text-white text-xs font-bold small-font">
                        {reviewCount}
                      </span>
                    </div>
                  )}
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button
                      className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                      onClick={() => setSearchValue("")}
                    >
                      <span className="sr-only">Open user menu</span>
                      {auth.account.avatar ? (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={auth.account.avatar}
                          alt="Avatar"
                        />
                      ) : (
                        <span
                          className={classNames(
                            "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400"
                          )}
                        >
                          <UserIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 border-b-2"
                              )}
                            >
                              {t(`navbar.${item.name}`)}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm"
                            )}
                            onClick={() => {
                              localStorage.removeItem("accessToken");
                              navigate("/signin");
                            }}
                          >
                            {t("navbar.logout")}
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 bg-gray-100 min-h-[calc(100vh-64px)]">
            <div className="py-6">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
};

